@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap");

.hero {
  width: 100%;
  .container-hero {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .imagehero {
      width: 100%;
      .gambarhero {
        width: 100%;
        background-image: url(../../asset/Hero-image.webp);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-size: contain;
        background-position: center;
        height: 100vh;
        background-size: cover;
        @media (max-width: 768px) {
          /* Adjust breakpoint as needed */
          background-image: url("../../asset/hero-mobile.webp");
          background-size: cover; /* Replace with your mobile image URL */
          height: 80vh;
          background-repeat: no-repeat;
          // border-radius: 0px 0px 0px 30px;
        }
        .navbar {
          width: 100%;
          height: 250px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          background-color: #2e7ba540;
          padding: 20px 0;
          @media (max-width: 768px) {
            padding: 0;
          }
          .logonavbar {
            width: 30%;
            text-align: center;
            @media (max-width: 768px) {
              width: 60%;
            }
            .gambar {
              width: 100%;
              animation-name: animasiLogo;
              animation-duration: 2s;
              animation-timing-function: ease;
              @keyframes animasiLogo {
                0% {
                  width: 0%;
                  padding-bottom: 10px;
                }
                100% {
                  width: 100%;
                }
              }
            }
          }
          .buttonwhatsapp {
            .whatsapp {
              padding: 15px 20px;
              font-size: 14px;
              background: linear-gradient(
                148deg,
                rgba(158, 255, 135, 1) 0%,
                rgba(121, 255, 99, 1) 47%,
                rgba(36, 136, 35, 1) 100%
              );
              color: white;
              text-shadow: 0px 0px 10px #a5a5a5;
              border-radius: 5px;
              margin-left: 10px;
              margin-right: 10px;
              cursor: pointer;
              border-width: 0px;
              &:hover {
                background: linear-gradient(
                  90deg,
                  rgb(69, 182, 48) 20%,
                  rgb(117, 255, 86) 100%
                );
                transition: 0.5s;
              }
            }
            .getstart {
              padding: 15px 40px;
              background: linear-gradient(
                148deg,
                rgba(135, 255, 207, 1) 0%,
                rgba(99, 220, 255, 1) 47%,
                rgba(40, 35, 136, 1) 100%
              );
              color: rgb(54, 54, 54);
              font-family: "DM Serif Display";
              font-weight: 400;
              font-size: 14px;
              border-radius: 5px;
              margin-left: 10px;
              margin-right: 10px;
              cursor: pointer;
              border-width: 0px;
              &:hover {
                background: linear-gradient(
                  rgba(99, 220, 255, 1) 0%,
                  rgba(40, 35, 136, 1) 100%
                );
                transition: 0.3s;
                color: white;
              }
            }
          }
        }
      }
    }
  }
}
