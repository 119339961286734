.fasilitas {
  width: 100%;
  margin-top: 8%;
  .container-fasilitas {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .header-fasilitas {
      text-align: center;
      width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .judul-fasilitas {
        width: 90%;
        font-size: 56px;
        font-family: "Playfair Display";
        color: #4477a8;
        @media (max-width: 768px) {
          font-size: 36px;
        }
      }
      .desk-judul {
        width: 70%;
        @media (max-width: 768px) {
          width: 98%;
        }
      }
    }
    .carousel-fasilitas {
      width: 95%;
      margin-top: 30px;
      @media (max-width: 768px) {
        width: 100%;
      }
      .slick-slider {
        width: 100%;
        .slick-next::before,
        .slick-prev::before {
          font-family: "slick";
          font-size: 30px;
        }
        .slick-list {
          bottom: -35px;
        }
        .slick-track {
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: center;
        }

        .slick-next {
          right: 25px;
        }
        .slick-prev {
          z-index: 1;
          left: 25px;
        }
      }
    }
  }
}
