.about {
  padding-top: 5%;
  padding-bottom: 5%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .container-about {
    width: 80%;
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      width: 98%;
      flex-direction: column;
    }
    .heading-about {
      width: 100%;
      .judul-about {
        font-family: "Playfair Display";
        font-size: 64px;
        color: #4477a8;
        font-weight: 300;
        b {
          font-weight: 600;
          font-size: 76px;
          @media (max-width: 768px) {
            font-size: 65px;
          }
        }
        @media (max-width: 768px) {
          width: 90%;
          font-size: 45px;
        }
      }
      .desk-about {
        font-size: 14px;
        line-height: 1.8;
        width: 90%;
      }
      .button {
        margin-top: 20px;
        .whatsapp {
          box-shadow: 0 0px 5px 0 rgb(69, 69, 69);
          padding: 15px 30px;
          font-size: 14px;
          background: linear-gradient(
            148deg,
            rgba(158, 255, 135, 1) 0%,
            rgba(121, 255, 99, 1) 47%,
            rgba(36, 136, 35, 1) 100%
          );
          color: white;
          border-radius: 5px;
          margin-left: 10px;
          margin-right: 10px;
          cursor: pointer;
          border-width: 0px;
          &:hover {
            background: linear-gradient(
              90deg,
              rgb(69, 182, 48) 20%,
              rgb(71, 220, 37) 100%
            );
            transition: 0.5s;
          }
        }
      }
    }
    .image {
      width: 100%;
      background: linear-gradient(
        148deg,
        rgba(135, 255, 207, 1) 0%,
        rgba(99, 220, 255, 1) 47%,
        rgba(40, 35, 136, 1) 100%
      );
      box-shadow: 0px 0px 10px 0px rgb(96, 96, 96) inset;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      @media (max-width: 768px) {
        margin-top: 20px;
      }
      img {
        width: 70%;
        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }
}
