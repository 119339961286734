@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.container-promo1 {
  background-image: url(../../asset/background.webp);
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
  padding: 20px;
  @media (max-width: 768px) {
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    height: 100%;
    background-image: url(../../asset/background-mobile.png);
    background-size: cover;
  }
  .container-listpromo {
    margin-top: 30px;
    height: 100%;
    width: 100%;
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
      margin-top: -15px;
    }
    .containergambar {
      order: 1;
      width: 100%;
      text-align: right;
      @media (max-width: 768px) {
        margin-top: 25px;
        order: 1;
        width: 100%;
        text-align: center;
      }

      .penawarangambar {
        width: 500px;
        height: 650px;
        box-shadow: 0 0 10px 0;
        border-radius: 5px;
        @media (max-width: 768px) {
          width: 90%;
          height: 100%;
        }
      }
    }

    .containercontent {
      order: 2;
      width: 100%;
      margin-left: 100px;
      text-align: left;
      justify-content: center;
      display: flex;
      flex-direction: column;
      @media (max-width: 768px) {
        margin-left: 25px;
        width: 90%;
        order: 2;
        margin-top: 30px;
      }
    }
    .judul-promo {
      width: 80%;
      font-weight: 500;
      font-size: 40px;
      line-height: 1em;
      color: #ffffff;
      font-family: "Poppins";
      @media (max-width: 768px) {
        width: 100%;
        font-size: 25px;
        margin-bottom: 17px;
      }
    }
    .disclaimer {
      margin-top: 25px;
      font-size: 13px;
    }
    .pointpenawaran {
      flex-direction: row;
      font-size: 30px;
      @media (max-width: 768px) {
        font-size: 25px;
        width: 100%;
      }
      .penawaranpoin {
        margin-top: 0px;
        color: #ffffff;
        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
    .button-wa-promo {
      padding-top: 15px;
      padding-bottom: 10px;
      .whatsapp {
        box-shadow: 0 0px 5px 0 rgb(69, 69, 69);
        padding: 15px 30px;
        font-size: 14px;
        background: linear-gradient(
          148deg,
          rgba(158, 255, 135, 1) 0%,
          rgba(121, 255, 99, 1) 47%,
          rgba(36, 136, 35, 1) 100%
        );
        color: white;
        border-radius: 5px;
        margin-left: 10px;
        margin-right: 10px;
        cursor: pointer;
        border-width: 0px;
        &:hover {
          background: linear-gradient(
            90deg,
            rgb(69, 182, 48) 20%,
            rgb(71, 220, 37) 100%
          );
          transition: 0.5s;
        }
      }
    }
  }
}
