@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.container-footer {
  width: 100%;
  display: flex;
  margin-top: 80px;
  margin-bottom: -160px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0 0 10px 0;
  .container-atas {
    width: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 15px;
    @media (max-width: 768px) {
      flex-direction: column;
      width: 100%;
      margin: 0px;
    }
    .container-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30%;
      padding: 20px;
      @media (max-width: 768px) {
        width: 50%;
        margin-bottom: -20px;
      }
      .gambar-logo {
        width: 80%;
        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
    .container-deskripsi {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 60%;
      padding: 20px;
      @media (max-width: 768px) {
        width: 80%;
      }
      .alamat {
        text-align: center;
        font-weight: 400;
        font-family: "Poppins";
        font-size: 14px;
        h2 {
          color: #8f8d8d;
          font-weight: 400;
          font-size: 28px;
          margin-bottom: -20px;
          @media (max-width: 768px) {
            font-size: 24px;
          }
        }
        h1 {
          font-size: 32px;
          color: rgba(40, 35, 136, 1);
          margin-bottom: -20px;
          @media (max-width: 768px) {
            font-size: 23px;
          }
        }
      }
      .contact {
        text-align: center;
        font-weight: 400;
        font-family: "Poppins";
        font-size: 12px;
      }
      //    .privasi{

      //  }
    }
  }
  .container-bawah {
    width: 100%;
    background: linear-gradient(
      148deg,
      rgba(135, 255, 207, 1) 0%,
      rgba(99, 220, 255, 1) 47%,
      rgba(40, 35, 136, 1) 100%
    );
    padding: 30px 0;
    text-align: center;
    color: white;
    font-weight: 600;
    font-family: "Roboto";
    font-size: 20px;
  }
}
